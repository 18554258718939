import Head from 'next/head';
import { useRouter } from 'next/router';

import { ENV } from 'utils/constants';

const Canonical = () => {
  const router = useRouter();

  let dynamicCanonicalURL;

  const {
    query,
    query: { subCategory },
  } = router;

  const url = router.asPath;

  const queryLength = Object.keys(query).length;

  const haveExactOneQuery =
    (subCategory && queryLength === 3) || queryLength === 2;

  const hasMultipleQueries =
    (subCategory && queryLength >= 3) || queryLength > 2;

  if (haveExactOneQuery && url.includes('page=')) {
    dynamicCanonicalURL = url.substring(0, url.indexOf('?') + 9);
  } else if (hasMultipleQueries) {
    dynamicCanonicalURL = url.substring(0, url.indexOf('&'));
  } else {
    dynamicCanonicalURL = url;
  }

  return (
    <Head>
      <link rel="canonical" href={`${ENV.baseHost}${dynamicCanonicalURL}`} />
    </Head>
  );
};

export default Canonical;
